import React from 'react'
import styles from './News.module.css'
import { MdWifi } from 'react-icons/md'
import { BsPlug } from 'react-icons/bs'
import { titles } from '../../Data/mainTitles'
import { newsItemsEnglish, newsItemsJapanese } from '../../Data/newsData'

function News({ isEnglish }) {

    const items = isEnglish ? newsItemsEnglish : newsItemsJapanese

    return(
        <div className={`news ${styles.newsArea}` }>
            <div className={styles.navDiv} id="news"><MdWifi className={styles.icon}/><BsPlug className={styles.icon}/></div>
            <h1 className={styles.newsTitle}>{titles.news}</h1>
            <div className={styles.newsContainer}>
                <div className={styles.newsArticleContainer}>
                    { items.map((item, index) => {
                        return (
                            item.link ?
                            <a className={`${styles.newsArticleWrapper} ${index !== items.length - 1 ? styles.articleUnderline : ''}`} href={item.link} key={index}>
                                <div className={styles.newsArticle}>
                                    { item?.image ? <img src={item.image} className={styles.newsArticleImage} alt="borden-news"/> : null}
                                    <div>
                                        <h2 className={styles.newsArticleTitle}>{item.title}</h2>
                                        { item.subTitle !== '' ? <h4 className={styles.newsArticleSubTitle}>{item.subTitle}</h4> : null}
                                        <p className={styles.newsArticleText}>{item.text}</p>
                                        <p className={styles.newsArticleDate}>{item.date}</p>
                                    </div>
                                </div>
                                <p className={styles.newsArticleTextSP}>{item.text}</p>
                                <p className={styles.newsArticleDateSP}>{item.date}</p>
                            </a>
                            : <div className={`${styles.newsArticleDivWrapper} ${index !== items.length - 1 ? styles.articleUnderline : ''}`} href={item.link} key={index}>
                                <div className={styles.newsArticle}>
                                    { item?.image ? <img src={item.image} className={styles.newsArticleImage} alt="borden-news"/> : null}
                                    <div>
                                        <h2 className={styles.newsArticleTitle}>{item.title}</h2>
                                        { item.subTitle !== '' ? <h4 className={styles.newsArticleSubTitle}>{item.subTitle}</h4> : null}
                                        <p className={styles.newsArticleText}>{item.text}</p>
                                        <p className={styles.newsArticleDate}>{item.date}</p>
                                    </div>
                                </div>
                                <p className={styles.newsArticleTextSP}>{item.text}</p>
                                <p className={styles.newsArticleDateSP}>{item.date}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default News