export const newsItemsEnglish = [
{

title: '📢 Year-End and New Year Schedule',
subTitle: 'We wanted to inform you of our operating schedule for the upcoming holiday season:',
text: 
`December 31st: Open as usual
New Year's Day: Closed
From January 2nd: Back to regular hours

Thank you & Happy New Year!
`,
date: '2023/12/29',
image: require('./Images/News/2024newyear.png'),
link: '' 

},
{
    
title: '📢 Join Our Team!',
subTitle: 'Hey there! 9 Borden Coffee is on the lookout for awesome staff members to join us!',
text: 
`For all the juicy details about job openings, just click on this link.

Got questions or something on your mind? Drop us a DM. We're eager to hear from you and can't wait for your applications!
`,
date: '2023/09/09',
image: require('./Images/News/IMG_7626.png'),
link: 'https://www.notion.so/Recruit-657d946af2824adc98d4d7bc60e93397' 

},
]

export const newsItemsJapanese = [
{

title: '📢年末年始のお知らせ',
subTitle: '営業は12/31は通常通り、元旦はお休み、1/2から通常通りです！',
text: 
`元旦のみお休みをいただきますが、他は通常通りです。ぜひ新年2日から遊びに来てくださいね:)
`,
date: '2023年12月29日',
image: require('./Images/News/2024newyear.png'),
link: ''

},
{

title: '📢スタッフ募集のお知らせ',
subTitle: '9 Borden Coffeeでは一緒に働いてくれるスタッフを募集しています!',
text: 
`募集についての詳細はこちらをクリックしてください。

質問や気になることなどございましたら、お気軽にDMにてお問合せ下さい。ご応募お待ちしております。
`,
date: '2023年9月9日',
image: require('./Images/News/IMG_7626.png'),
link: 'https://www.notion.so/Recruit-657d946af2824adc98d4d7bc60e93397'

},
]




// export const newsItemsEnglish = [
// {
    
// title: 'THIS IS THE NEWS TITLE',
// subTitle: 'THIS IS THE SUBTITLE OF THE NEWS ARTICLE',
// text: 
// `This is the main text body. This is where the details about the news can go.
// `,
// date: '01/01/2023',
// image: '',
// link: '' 

// },
// {

// title: 'THIS IS THE NEWS TITLE',
// subTitle: 'THIS IS THE SUBTITLE OF THE NEWS ARTICLE',
// text: `This is the main text body. This is where the details about the news can go.`,
// date: '01/01/2023',
// image: require('./Images/News/news1.jpeg'),
// link: 'https://en.wikipedia.org/wiki/Main_Page'

// },
// {

// title: 'THIS IS THE NEWS TITLE',
// subTitle: 'THIS IS THE SUBTITLE OF THE NEWS ARTICLE',
// text: `This is the main text body. T the news can go.`,
// date: '01/01/2023',
// image: require('./Images/News/news1.jpeg'),
// link: 'https://en.wikipedia.org/wiki/Main_Page'

// },
// ]

// export const newsItemsJapanese = [
// {

// title: 'テストタイトルです',
// subTitle: 'テストサブタイトルです',
// text: 
// `これはテストです。これはテストです。これはテストです。これはテストです。
// `,
// date: '2023年1月1日',
// image: '',
// link: ''

// },
// {

// title: 'テストタイトルです',
// subTitle: 'テストサブタイトルです',
// text: 
// `これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。`,
// date: '2023年1月1日',
// image: require('./Images/News/news1.jpeg'),
// link: 'https://en.wikipedia.org/wiki/Main_Page'

// },
// {

// title: 'テストタイトルです',
// subTitle: 'テストサブタイトルです',
// text: 
// `これはテストです。これはテストです。これはテストです。これはテストです。これはテストです。`,
// date: '2023年1月1日',
// image: require('./Images/News/news1.jpeg'),
// link: 'https://en.wikipedia.org/wiki/Main_Page'

// },
// ]