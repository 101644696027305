export const accessText = {
    englishLocation: "3 Chome-7-15 Chikko, Minato Ward, Osaka, 552-0021",
    englishTransport: "2 minute walk from Osakako Station (Chuo Line)",
    englishPhone: "090-6605-9192",
    englishEmail: "shop@9bordencoffee.com",
    japaneseLocation: "大阪市港区築港3丁目7−15 港振興ビル 111号室",
    japaneseTransport: "大阪メトロ中央線 大阪港駅から徒歩2分",
    japanesePhone: "090-6605-9192",
    japaneseEmail: "shop@9bordencoffee.com",

    englishMonday: "7:30 - 18:00",
    englishTuesday: "7:30 - 18:00",
    englishWednesday: "7:30 - 18:00",
    englishThursday: "7:30 - 18:00",
    englishFriday: "7:30 - 18:00",
    englishSaturday: "7:30 - 18:00",
    englishSunday: "7:30 - 18:00",
    
    japaneseMonday: "7:30　〜　18:00",
    japaneseTuesday: "7:30　〜　18:00",
    japaneseWednesday: "7:30　〜　18:00",
    japaneseThursday: "7:30　〜　18:00",
    japaneseFriday: "7:30　〜　18:00",
    japaneseSaturday: "7:30　〜　18:00",
    japaneseSunday: "7:30　〜　18:00",
}